
import React from 'react';

const ItemModal = ({ name, img, descr, text, close_text, more_text, onClose, onMore, id }) => {

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal__top">
                    <div className="modal__top-img">
                        <img src={img} alt="" />
                    </div>
                    <div className="modal__top-right">
                        <div className="modal__top-right__title">{name}</div>
                        <div className="modal__top-right__descr">{descr}</div>
                    </div>
                </div>
                <div className="modal__middle" dangerouslySetInnerHTML={{ __html: text }}>
                </div>
                <div className="modal__bottom">
                    <button className="modal__btn" onClick={onClose}>{close_text}</button>
                    <button id={id}  className="modal__btn  modal_act"
                     onClick={(event) => onMore(event)}
                     >{more_text}</button>
                </div>
            </div>
        </div>
    );
};

export default ItemModal;