import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarUpload from './avatar';
import Thanks from './thanks';
import closeIcon from '../img/icons8-close-80.png'
import './style.css';

const Download = () => {
    const [telegram, setTelegram] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [link, setUrl] = useState('');
    const [image, setImage] = useState(null); // Здесь будет сохраняться выбранное изображение
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/');
    };
    const maxLength = 450;

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'telegram':
                setTelegram(value);
                break;
            case 'description':
                setDescription(e.target.value);
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleImageChange = (file) => {
        setImage(file); // Сохраняем изображение в состоянии
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let formErrors = {};
        if (!telegram) {
            formErrors.telegram = 'Enter telegram your name';
        }
        if (!description) {
            formErrors.description = 'Enter description';
        }

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const formData = new FormData();
        formData.append('telega', telegram);
        formData.append('description', description);
//        const csrftoken = getCookie('csrftoken');

        fetch('/api/order/', {
            method: 'POST',
            body: formData,
            redirect: "follow",
            headers: {
                'Accept': '*/*',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setIsSubmitted(true);
            } else {
                setErrors(data.errors || {});
            }
        })
        .catch(error => {
            console.error('Error send form:', error);
        });
    };

    return (
        <div className='wrapper mobile'>
            {isSubmitted ? (
                <Thanks />
            ) : (
                <>
                    {Object.values(errors).some(error => error) && (
                        <div className="form__block">
                            <div className="form__error">
                                <ul>
                                    {errors.telegram && (
                                        <li>
                                            <span className="form__error-text">{errors.telegram}</span>
                                        </li>
                                    )}
                                    {errors.companyName && (
                                        <li>
                                            <span className="form__error-text">{errors.companyName}</span>
                                        </li>
                                    )}
                                    {errors.category && (
                                        <li>
                                            <span className="form__error-text">{errors.category}</span>
                                        </li>
                                    )}
                                    {errors.description && (
                                        <li>
                                            <span className="form__error-text">{errors.description}</span>
                                        </li>
                                    )}
                                    {errors.url && (
                                        <li>
                                            <span className="form__error-text">{errors.url}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="form__block">
                        <div className="form__close"><img onClick={handleClick} src={closeIcon} alt="" /></div>
                        <form onSubmit={handleSubmit}>
                            <h4 className='form__title'>Feedback</h4>
                            <div className='form__grup'>
                                <input
                                    placeholder=" "
                                    className='form__grup-input'
                                    type="text"
                                    id="telegram"
                                    name="telegram"
                                    value={telegram}
                                    onChange={handleChange}
                                />
                                <label className='form__grup-label' htmlFor="telegram">Your telegram:</label>
                            </div>
                            <div className='form__grup'>
                                <textarea
                                    placeholder=" "
                                    className='form__grup-textarea'
                                    id="description"
                                    name="description"
                                    value={description}
                                    maxLength={maxLength}
                                    onChange={handleChange}
                                />
                                <label className='form__grup-label__textarea' htmlFor="description">Description ({description.length} from {maxLength}):</label>
                            </div>
                            <button type="submit" className='form__btn'>Send</button>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

export default Download;