import { useNavigate } from 'react-router-dom';

import './style.css'

const Thanks = () => {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/');
    };

    return <div className="wrapper">
        <div className="thanks">
            <div className="thanks__logo">
                trusthouse
            </div>
            <div className="thanks__text">We will contact you as soon as possible</div>
            <div className="header__lang back" onClick={handleClick}>Back</div>

        </div>
    </div>
}

export default Thanks
